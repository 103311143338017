<template>
    <div class="container-fluid">
        <div class="page-content pic-page" id="page-content">
            <div class="header-greeting mb-3">
                <p class="p-0 m-0 text-white">
                   Recopedia
                </p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder text-white">Dokter Detail</h1>
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <router-link to="/pic/dokter">
                         <button type="button" class="btn btn-add-main px-5 mb-2">Kembali</button>
                        </router-link>
                    </div>
                </div>
            </div>
            <section id="patient-details-card">
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-bind:style="{ display: notif_alert.alert }"
                >
                    {{ notif_alert.alertMessage }}
                </div>
                <div class="card p-4">
                    <div v-if="loadDetail">
                        <h3 class="text-center">
                            <i class="fa fa-spinner fa-spin"></i>
                        </h3>
                    </div>
                    <template v-else>
                        <div class="d-flex align-items-center mb-3">
                            <img v-bind:src="[dokter_data.image != null ?  dokter_data.image : '']" class="me-3 img-fluid"  alt="doc.png" >
                            <h3 class="mb-0">{{dokter_data.name}}</h3>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-3 mb-2 ">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">NPA</div>
                                        <div class="detail-main-text">{{dokter_data.npa}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-auto mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">Jumlah Pasien</div>
                                        <div class="detail-main-text">{{dokter_data.jumlah_pasien}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-auto mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">No HP (WhatsApp)</div>
                                        <div class="detail-main-text">{{dokter_data.phone}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-auto mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">Terakhir Periksa</div>
                                        <div class="detail-main-text">{{dokter_data.last_login}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <div class="patient-card mt-5 mb-5">
               <h4 class="fw-bold text-white">List Pasien</h4>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card" style="min-height: auto">
                            <div class="card-body pb-0">
                                <div class="text-end">
                                    <div class="title mb-2">
                                        Urutkan Berdasarkan
                                    </div>
                                </div>
                                <form @submit.prevent="onLoadPasien">
                                    <div class="row filter-input">
                                        <div class="col-md-7 mb-2">
                                            <div class="search-input">
                                                <input
                                                    type="text"
                                                    name="patient"
                                                    id="patient-search"
                                                    v-model="patientSearch"
                                                    v-on:keyenter="onFilter()"
                                                    class="form-control"
                                                    placeholder="Cari Pasien"
                                                />
                                                <i class="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-6 mb-2">
                                            <select
                                                name="name"
                                                id="sortName"
                                                v-model="sortName"
                                                v-on:change="onFilter()"
                                                class="form-select"
                                            >
                                                <option value="parent_name">
                                                    Nama Orang Tua
                                                </option>
                                                <option value="name">
                                                    Nama Anak
                                                </option>
                                                <option value="birth_date">
                                                    Tanggal Lahir
                                                </option>
                                                <option value="last_check">
                                                    Tanggal Periksa
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-2 col-6 mb-2">
                                            <select
                                                name="alphaName"
                                                id="alphaName"
                                                v-model="alphaName"
                                                v-on:change="onFilter()"
                                                class="form-select"
                                            >
                                                <option value="asc">
                                                    A - Z
                                                </option>
                                                <option value="desc">
                                                    Z - A
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body pt-0 px-0">
                                <div class="table-responsive">
                                    <!-- Should be DataTables -->
                                    <table class="table">
                                        <thead>
                                            <tr class="text-left">
                                                <th>No</th>
                                                <th>Nama Lengkap</th>
                                                <th>Tanggal Lahir</th>
                                                <th>Umur</th>
                                                <th>No HP</th>
                                                <th>Terakhir Periksa</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadPasien">
                                                <td
                                                    colspan="7"
                                                    class="text-center"
                                                >
                                                    <h1>
                                                        <i
                                                            style="color: #4d3180"
                                                            class="
                                                                fa
                                                                fa-spinner
                                                                fa-spin
                                                            "
                                                        ></i>
                                                    </h1>
                                                </td>
                                            </tr>
                                            <template
                                                v-for="(
                                                    item, index
                                                ) in listPasien"
                                                v-else
                                            >
                                                <tr
                                                    class="
                                                        align-middle
                                                        text-left
                                                    "
                                                    :key="item.id"
                                                >
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        {{
                                                            item.birth_date_format
                                                        }}
                                                    </td>
                                                    <td>{{ item.age }}</td>
                                                    <td>{{ item.phone }}</td>
                                                    <td>
                                                        {{
                                                            item.last_check_format
                                                        }}
                                                    </td>
                                                    <td>
                                                        <router-link
                                                            :to="{
                                                                name: 'pic_dokter_pasien_detail',
                                                                params: {
                                                                    id: $route.params.id,
                                                                    pasien:item.id
                                                                },
                                                            }"
                                                            style="
                                                                text-decoration: none;
                                                            "
                                                        >
                                                            <button
                                                                type="button"
                                                                class="
                                                                    d-inline
                                                                    btn
                                                                    btn-details
                                                                    my-1
                                                                    me-2
                                                                "
                                                            >
                                                                <i
                                                                    class="
                                                                        far
                                                                        fa-eye
                                                                        me-2
                                                                    "
                                                                ></i
                                                                >Lihat
                                                            </button>
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-info" v-if="paging_show">
                                    <h6 class="mb-0 text-muted">
                                        <small>Rows per page: </small>
                                    </h6>
                                    <div class="dropdown-container">
                                        <select
                                            name="rows"
                                            id="pageRow"
                                            class="form-select page-select"
                                            v-on:change="onChangePerpage()"
                                            v-model="perpage"
                                        >
                                            <option value="7">7</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                        </select>
                                        <!-- <i class="fas fa-chevron-down"></i> -->
                                    </div>
                                    <div class="mx-3 paging-of">
                                        <small>{{ page_info }}</small>
                                    </div>
                                    <button
                                        class="btn"
                                        :disabled="disabled_prev"
                                        v-on:click="prevPage()"
                                    >
                                        <i class="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                        class="btn"
                                        :disabled="disabled_next"
                                        v-on:click="nextPage()"
                                    >
                                        <i class="fas fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    </div>
</template>
<script>
import { getError } from "@/utils/helpers";
import PicService from "@/services/PicService";

export default {
    data() {
        return {
            loadDetail : false,
            dokter_data:{
                image:null,
                name:null,
                npa:null,
                total_pasien:null,
                phone:null,
                last_login:null
            },
            notif_alert: {
                alert:"none",
                alertMessage:""
            },
            patientSearch:"",
            alphaName: 'asc',
            sortName: 'parent_name',
            listPasien:[],
            loadPasien: true,
            page: 1,
            perpage: 7,
            last_page: 1,
            page_info: '',
            index_data: 1,
            paging_show:false,
            disabled_next: false,
            disabled_prev: false,
        }
    },
    created() {
        document.title = 'Detail Dokter'
        document.body.setAttribute('id', 'pic');
        this.onDetailDokter()
        this.onLoadPasien()
    },
    computed: {
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    methods:{
        async onDetailDokter() {
            this.loadDetail = true;
            await PicService.detailDokter(this.$route.params.id)
                .then((response) => this.onResultDokter(response))
                .catch((error) => getError(error));
        },
        onResultDokter(response){
            if (response.data.result == 'error') {
                this.notif_alert.alert = 'block';
                this.notif_alert.alertMessage = response.data.messsage;
            } else {
                this.dokter_data.image = response.data.data.avatar
                this.dokter_data.name = response.data.data.name
                this.dokter_data.phone = response.data.data.phone
                this.dokter_data.jumlah_pasien = response.data.data.total_pasien_format
                this.dokter_data.last_login = response.data.data.last_login_format
                this.dokter_data.npa = response.data.data.npa
            }
            this.loadDetail = false
        },
        async onLoadPasien() {
            this.loadPasien = true;
            const payload = {
                params: {
                    order: this.sortName,
                    sort: this.alphaName,
                    search: this.patientSearch,
                    perpage: this.perpage,
                    page: this.page,
                },
                id:this.$route.params.id
            };
            await PicService.getPasien(payload)
                .then((response) => this.onResultPasien(response))
                .catch((error) => getError(error));
        },
        onResultPasien(response) {
            this.listPasien = response.data.data;
            this.loadPasien = false;
            this.last_page = response.data.last_page;
            if(this.listPasien.length > 0) {
                this.paging_show = true
            } else {
                this.paging_show = false
            }

            this.page_info =
                response.data.current_page +
                '-' +
                response.data.last_page +
                ' of ' +
                response.data.to;
            this.index_data = response.data.from;

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        onFilter() {
            this.page = 1;
            this.onLoadPasien();
        },
        nextPage() {
            if (this.page < this.last_page) {
                this.page++;
                this.onLoadPasien();
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        prevPage() {
            if (this.page > 1) {
                this.page--;
                this.onLoadPasien();
            }

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }
        },
        onChangePerpage() {
            this.page = 1;
            this.onLoadPasien();
        },
    }

}
</script>
