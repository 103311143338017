<template>
    <section id="login-page">
        <div class="container-fluid m-0 p-0">
            <div class="login-top">
                <div class="row" style="margin-right:0px; margin-left:0px;">
                    <div class="col-md-12">
                        <div class="main-logo-title">
                            <img
                                src="../assets/images/recopedia-white.png"
                                alt="logo"
                                width="200"
                            />
                        </div>
                        <h5 class="text-white text-center">Silahkan Masuk</h5>
                        <div class="text-center text-white mt-3 mb-4 mx-auto">
                            <h6>
                                Anda bisa login menggunakan akun yang<br />
                                sudah pernah dibuat
                            </h6>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="login-card">
                            <div class="card mx-auto p-4 shadow">
                                <!-- Form Login -->
                                <form @submit.prevent="login" id="login-form">
                                    <div
                                        class="alert"
                                        v-bind:class="[alertType]"
                                        role="alert"
                                        v-bind:style="{ display: alert }"
                                    >
                                        {{ alertMessage }}
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email" class="mb-2"
                                            >Email</label
                                        >
                                        <input
                                            type="text"
                                            :readonly="isRead"
                                            v-model="email"
                                            class="form-control"
                                            id="email"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="password" class="mb-2"
                                            >Password</label
                                        >
                                        <input
                                            type="password"
                                            :readonly="isRead"
                                            v-model="password"
                                            class="form-control"
                                            id="password"
                                            placeholder="Masukkan Password"
                                        />
                                    </div>
                                    <div class="my-4 link-help">
                                        Anda lupa password?
                                        <span class="fw-bold"
                                            ><router-link to="/forgot-password">Klik Disini</router-link></span
                                        >
                                    </div>
                                    <div class="d-grid my-3">
                                        <button
                                            v-if="loadLogin"
                                            type="button"
                                            disabled
                                            class="btn btn-main-login"
                                        >
                                            <i
                                                class="fa fa-spinner fa-spin"
                                            ></i>
                                            MASUK
                                        </button>
                                        <button
                                            v-else
                                            type="submit"
                                            class="btn btn-main-login"
                                            v-on:click="login()"
                                        >
                                            MASUK
                                        </button>
                                    </div>
                                </form>
                                <!-- End Form Login -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-3 pt-5 fw-bold">
                        <footer>
                            <!-- © 2021 PT ABBOTT PRODUCTS INDONESIA. ALL RIGHTS
                            RESERVED -->
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import PicService from '@/services/PicService';
import { getError } from '@/utils/helpers';

export default {
    name: 'Piclogin',
    data() {
        return {
            email: null,
            password: null,
            error: null,
            loadLogin: false,
            isRead: false,
            alert: 'none',
            alertMessage: '',
            alertType: '',
        };
    },
    created() {
        document.body.setAttribute('id', '');
    },
    methods: {
        async login() {
            const payload = {
                email: this.email,
                password: this.password,
                device_name: 'web',
            };
            this.error = null;
            this.loadLogin = true;
            this.isRead = true;
            try {
                const response = await PicService.login(payload);
                if (response.data.result == 'success') {
                    this.loadLogin = false;
                    this.isRead = false;

                    const token = response.data.token.split('|')[1];

                    this.$store.dispatch('pic/setToken', { value: token });

                    await this.$store.dispatch('pic/getAuthUser');
                    this.$router.push('/pic/dashboard');
                } else {
                    this.loadLogin = false;
                    this.isRead = false;

                    this.alertType = 'alert-danger';
                    this.alert = 'block';
                    this.alertMessage = response.data.message;
                    const error = Error(
                        'Unable to fetch user after login, check your API settings.'
                    );
                    error.name = 'Fetch User';
                    throw error;
                }
            } catch (error) {
                this.error = getError(error);
            }
        },
    },
};
</script>
