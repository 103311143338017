<template>
    <div class="container-fluid">
        <div
            class="page-content ml-sm-0 pic-page"
            id="page-content "
        >
            <div class="header-greeting mb-3">
                <p class="p-0 m-0">Recopedia</p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder">Pasien Detail</h1>
                    </div>
                    <div class="col-sm-12 col-md-6 text-md-end">
                        <router-link :to="{
                                name: 'pic_dokter_detail',
                                params: {
                                    id: $route.params.id,
                                },
                            }">
                            <button
                                type="button"
                                class="btn btn-add-main px-5 mb-2"
                            >
                                Kembali
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <section id="patient-details-card">
                <div
                    class="alert alert-danger"
                    role="alert"
                    v-bind:style="{ display: alert }"
                >
                    {{ alertMessage }}
                </div>
                <div class="card p-4">
                    <div v-if="loadPasien">
                        <h3 class="text-center">
                            <i class="fa fa-spinner fa-spin"></i>
                        </h3>
                    </div>
                    <template v-else>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div
                                    class="label-1 avenir-light"
                                    style="color: #93b0c8"
                                >
                                    Orang Tua / Wali
                                </div>
                                <h3 class="title-text fw-bold">
                                    {{ namePatient }}
                                </h3>
                            </div>
                            <div class="col-12 col-md-6">
                                <div
                                    class="label-1 avenir-light"
                                    style="color: #93b0c8"
                                >
                                    Anak
                                </div>
                                <h3 class="title-text fw-bold">{{ name }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            No HP (WhatsApp)
                                        </div>
                                        <div class="detail-main-text">
                                            {{ phone }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-2 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">Umur</div>
                                        <div class="detail-main-text">
                                            {{ umur }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-auto mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Tanggal Lahir
                                        </div>
                                        <div class="detail-main-text">
                                            {{ date_birth }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Jenis Kelamin
                                        </div>
                                        <div class="detail-main-text">
                                            {{ genderPatient }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Status Kelahiran
                                        </div>
                                        <div class="detail-main-text">
                                            {{ birthStatus }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Catatan Tambahan
                                        </div>
                                        <div class="detail-main-text">
                                            {{ notes }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-sm-12 mb-2">
                                <div class="detail-wrapper d-flex">
                                    <div>
                                        <div class="detail-title">
                                            Terakhir Periksa
                                        </div>
                                        <div class="detail-main-text">
                                            {{ last_check }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <section id="history-checkup">
                <h3 class="mt-5">Riwayat Periksa</h3>
                <div class="row">
                    <div class="col-12">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            v-bind:style="{ display: alertHistory }"
                        >
                            {{ alertMessageHistory }}
                        </div>
                        <div class="card p-3">
                            <div class="title mb-2">Pilih Tanggal Periksa</div>
                            <!-- <input type="text" name="daterange" class="form-control" v-model="filerDate" v-on:keydown="onFilter()"/> -->
                            <date-range-picker
                                ref="picker"
                                :opens="opens"
                                :locale-data="localData"
                                v-model="dateRange"
                                :showWeekNumbers="false"
                                :showDropdowns="true"
                                :linkedCalendars="false"
                                @update="update"
                            >
                                <template style="min-width: 350px">
                                    {{ dateRange.startDate }} -
                                    {{ dateRange.endDate }}
                                </template>
                            </date-range-picker>

                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Tanggal Periksa</th>
                                            <th>Berat Badan</th>
                                            <th>Tinggi Badan</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="loadHistory">
                                            <td colspan="6" class="text-center">
                                                <h1>
                                                    <i
                                                        style="color: #4d3180"
                                                        class="
                                                            fa
                                                            fa-spinner
                                                            fa-spin
                                                        "
                                                    ></i>
                                                </h1>
                                            </td>
                                        </tr>
                                        <template
                                            v-for="(item, index) in listHistory"
                                            v-else
                                        >
                                            <tr
                                                class="align-middle"
                                                :key="item.id"
                                            >
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td>
                                                    <span
                                                        v-html="
                                                            getTanggalPeriksa(
                                                                item.hasil_pemeriksaan
                                                            )
                                                        "
                                                    ></span>
                                                </td>
                                                <td>
                                                    <span
                                                        v-html="
                                                            getBerat(
                                                                item.hasil_pemeriksaan
                                                            )
                                                        "
                                                    ></span>
                                                </td>
                                                <td>
                                                    <span
                                                        v-html="
                                                            getTinggi(
                                                                item.hasil_pemeriksaan
                                                            )
                                                        "
                                                    ></span>
                                                </td>
                                                <td>
                                                    <button
                                                        v-on:click="onDetail(item.id,item.hasil_pemeriksaan)"
                                                        type="button"
                                                        class="
                                                            btn btn-details
                                                            my-2
                                                            text-nowrap
                                                        "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalDetailPatient"
                                                    >
                                                        <i
                                                            class="
                                                                far
                                                                fa-edit
                                                                me-2
                                                            "
                                                        ></i
                                                        >Lihat Detail
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <detail-history v-model="loadKalkulator" :pasien="dataPasien" :options="optionDetailHistory" :set_data="detailData" @on_result="onResultDetail"></detail-history>
    </div>
</template>
<script>
import PicService from '@/services/PicService';
import { getError } from '@/utils/helpers';

import DateRangePicker from 'vue2-daterange-picker';
import DetailHistory from '@/components/DetailHistory';
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'detail_pasien',
    components: { DateRangePicker, DetailHistory },
    data() {
        let startDate = new Date();
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6);
        return {
            loadKalkulator:false,
            isShowModal: '',
            backdrop: '',
            displayModal: 'none',
            spin: '',
            loadHistory: true,
            listHistory: [],
            inputRead: false,
            namePatient: '',
            phonePatient: '',
            childName: '',
            birthdatePatient: '',
            genderPatient: '',
            genderPatients:'',
            birthStatus: '',
            format_phone:"62",
            notes: '',
            name: '',
            umur: '',
            date_birth: '',
            phone: '',
            last_check: '',
            alert: 'none',
            alertMessage: '',
            alertForm: 'none',
            alertMessageForm: '',
            alertHistory: 'none',
            alertMessageHistory: '',
            loadPasien: true,
            filerDate: 'Filter Date',
            startDate: '',
            endDate: '',
            opens: 'right',
            dateRange: { startDate, endDate },
            localData: {
                format: 'mm/dd/yyyy',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                monthNames: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
                firstDay: 1,
            },
            optionDetailHistory: {
                isShowModal: '',
                backdrop: '',
                displayModal: 'none',
                type:"pic"
            },
            dataPasien:{
                parent:"",
                name:"",
                phone:"",
                id_history:""
            },
            detailData: null,
        };
    },
    created() {
        this.onLoadPasien();
        document.title = 'Detail Pasien'
        document.body.setAttribute('id', 'pic');
        let startDate = new Date();
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6);
        this.startDate =
            startDate.getFullYear() +
            '-' +
            ('0' + (startDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + startDate.getDate()).slice(-2);
        this.endDate =
            endDate.getFullYear() +
            '-' +
            ('0' + (endDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + endDate.getDate()).slice(-2);
        this.onLoadHistory();
    },
    mounted() {
        //    window.$('input[name="daterange"]').daterangepicker({
        //       opens: "right",
        //       showDropdowns: true,
        //       buttonClasses : "btn",
        //       applyButtonClasses: "btnDate",
        //       cancelButtonClasses: "btnCancel",
        //   }, function(start, end, label) {
        //      var l = label;
        //      var s = start;
        //      var e = end;
        //      console.log(l);
        //      console.log(s);
        //      console.log(e);
        //   });
    },
    computed: {
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }

            if (width < 768) {
                return '80px';
            }

            return '0px';
        },
    },
    methods: {
        async onLoadPasien() {
            this.loadPasien = true;
            const payload = {
                dokter:this.$route.params.id,
                pasien:this.$route.params.pasien
            }
            await PicService.detailPasien(payload)
                .then((response) => this.onResultPasien(response))
                .catch((error) => getError(error));
        },
        onResultPasien(response) {
            if (response.data.result == 'error') {
                this.alert = 'block';
                this.alertMessage = response.data.messsage;
            } else {
                this.last_check = response.data.data.last_check_format;
                this.umur = response.data.data.age;
                this.name = response.data.data.name;
                this.phone = response.data.data.phone;
                this.date_birth = response.data.data.birth_date_format;

                this.namePatient = response.data.data.parent_name;
                this.phonePatient = response.data.data.phone;
                this.childName = response.data.data.name;
                this.birthdatePatient = response.data.data.birth_date;
                this.genderPatient = response.data.data.gender_format;
                this.genderPatients = response.data.data.gender;
                this.birthStatus = response.data.data.birth_status;
                this.notes = response.data.data.note;

                this.dataPasien.parent = response.data.data.parent_name;
                this.dataPasien.name = response.data.data.name;
                this.dataPasien.phone = response.data.data.phone;

                this.loadPasien = false;
            }
        },
        async onLoadHistory() {
            this.loadHistory = true;
            const payload = {
                params: {
                    date_1: this.startDate,
                    date_2: this.endDate,
                },
                dokter:this.$route.params.id,
                pasien:this.$route.params.pasien
            };
            await PicService.getHistory(payload)
                .then((response) => this.onResultHistory(response))
                .catch((error) => getError(error));
        },
        onResultHistory(response) {
            if (response.data.result == 'error') {
                this.alertHistory = 'block';
                this.alertMessageHistory = response.data.messsage;
            } else {
                this.loadHistory = false;
                this.listHistory = response.data.data;
            }
        },
        getBerat(item) {
            var response = JSON.parse(item);
            return response.output.status_berat_badan.rawoutput;
        },
        getTinggi(item) {
            var response = JSON.parse(item);
            return response.output.status_tinggi_badan.rawoutput;
        },
        getTanggalPeriksa(item) {
            var response = JSON.parse(item);
            return response.output.tanggal_periksa;
        },
        onDetail(id, item) {
            var response = JSON.parse(item);
            this.detailData = response
            this.dataPasien.id_history = id
            this.openDetailHistory()
        },
        openModal() {
            if (this.isShowModal == 'show') {
                this.isShowModal = '';
                this.displayModal = 'none';
                this.backdrop = '';
            } else {
                this.isShowModal = 'show';
                this.displayModal = 'block';
                this.backdrop = 'modal-backdrop show';
            }
        },
        closeModal() {
            this.backdrop = '';
            this.isShowModal = '';
            this.displayModal = 'none';
        },
        update() {
            const start = new Date(this.dateRange.startDate);
            const startFormat =
                start.getFullYear() +
                '-' +
                ('0' + (start.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + start.getDate()).slice(-2);

            const end = new Date(this.dateRange.endDate);
            const endtFormat =
                end.getFullYear() +
                '-' +
                ('0' + (end.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + end.getDate()).slice(-2);

            this.startDate = startFormat;
            this.endDate = endtFormat;

            this.onLoadHistory();
        },
        openDetailHistory() {
            this.loadKalkulator = true
            if (this.optionDetailHistory.isShowModal == 'show') {
                this.optionDetailHistory.isShowModal = '';
                this.optionDetailHistory.displayModal = 'none';
                this.optionDetailHistory.backdrop = '';
            } else {
                this.optionDetailHistory.isShowModal = 'show';
                this.optionDetailHistory.displayModal = 'block';
                this.optionDetailHistory.backdrop = 'modal-backdrop show';
            }
        },
        onResultDetail(val) {
            this.loadKalkulator = val
        }
    },
};
</script>
