<template>
    <section id="login-page">
        <div class="container-fluid m-0 p-0">
            <div class="login-top">
                <div class="row" style="margin-right:0px; margin-left:0px;">
                    <div class="col-md-12">
                        <div class="main-logo-title">
                            <img
                                src="../assets/images/recopedia-white.png"
                                alt="logo"
                                width="200"
                            />
                        </div>
                        <h5 class="text-white text-center">Silahkan Masuk</h5>
                        <div class="text-center text-white mt-3 mb-4 mx-auto">
                            <h6>
                                Anda bisa login menggunakan akun yang<br />
                                sudah pernah dibuat
                             
                            </h6>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="login-card">
                            <div class="card mx-auto p-4 shadow">
                                <!-- Button Social -->
                                <sosmed
                                    :options="optionsSosmed"
                                    :notif_alert="notif_alert"
                                    :load="loadLogin"
                                    @login_on="onListenLogin"
                                ></sosmed>
                                <!-- End Button Social -->
                                <div class="separator">
                                    <div class="line"></div>
                                    <div
                                        class="
                                            text-center
                                            bg-white
                                            separator-text
                                        "
                                    >
                                        Atau
                                    </div>
                                </div>
                                <!-- Form Login -->
                                <form @submit.prevent="login" id="login-form">
                                    <div
                                        class="alert"
                                        v-bind:class="[notif_alert.alertType]"
                                        role="alert"
                                        v-bind:style="{
                                            display: notif_alert.alert,
                                        }"
                                    >
                                        {{ notif_alert.alertMessage }}
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email" class="mb-2"
                                            >Email</label
                                        >
                                        <input
                                            type="email"
                                            :readonly="isRead"
                                            v-model="email"
                                            class="form-control"
                                            id="email"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label for="password" class="mb-2"
                                            >Password</label
                                        >
                                        <input
                                            type="password"
                                            :readonly="isRead"
                                            v-model="password"
                                            class="form-control"
                                            id="password"
                                            placeholder="Masukkan Password"
                                        />
                                    </div>
                                    <div class="form-group mt-3 d-flex flex-row align-items-start">
                                        <input  type="checkbox"  class="pl-3" id="flexCheckDefault" style="margin-right:10px;margin-top:4px;" v-on:change="handleCheckBox()">
                                        <div class="d-flex flex-column">
                                               <p>Dengan mendaftar & masuk, Saya mengkonfirmasi bahwa data yang diberikan adalah benar. Data Anda tersimpan aman di dalam layanan jaringan kami sesuai dengan informasi yang diberikan.</p>
                                            <div class="has-error" style="color:red;" v-if="!optionsSosmed.isCheckbox">Anda harus setuju sebelum login.</div>
                                        </div>
                                    </div>

                                    <div class="my-4 link-help">
                                        Anda lupa password?
                                        <span class="fw-bold"
                                            ><router-link to="/forgot-password"
                                                >Klik Disini</router-link
                                            ></span
                                        >
                                    </div>
                                    <div class="d-grid my-3">
                                        <button
                                            v-if="loadLogin"
                                            type="button"
                                            disabled
                                            class="btn btn-main-login"
                                        >
                                            <i
                                                class="fa fa-spinner fa-spin"
                                            ></i>
                                            MASUK
                                        </button>
                                        <button
                                            v-else
                                            type="button"
                                            class="btn btn-main-login"
                                            v-on:click="login()"
                                        >
                                            MASUK
                                        </button>
                                    </div>
                                    <div class="mt-4 link-help">
                                        Anda belum punya akun? silahkan
                                        <span class="fw-bold"
                                            ><router-link to="/register"
                                                >Daftar Disini</router-link
                                            ></span
                                        >
                                    </div>
                                </form>
                                <!-- End Form Login -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-3 pt-5 fw-bold mb-5">
                        <footer>
                            <!-- © 2022 PT ABBOTT PRODUCTS INDONESIA. ALL RIGHTS
                            RESERVED -->
                            <!-- <img src="../assets/images/anhi-logo-OG.png" style="width:150px;"/> -->
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getError } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import AuthService from '@/services/AuthService';
import Sosmed from '@/components/Sosmed';

export default {
    name: 'LoginView',
    components: { Sosmed },
    data() {
        return {
            email: null,
            password: null,
            error: null,
            loadLogin: false,
            isRead: false,
            alert: 'none',
            alertMessage: '',
            alertType: '',
            optionsSosmed: {
                type: 'login',
                isCheckbox:false,
            },
            notif_alert: {
                alert: 'none',
                alertMessage: '',
                alertType: '',
            },
        };
    },
    created() {
        this.onRegisterCheck();
        document.title = 'Login - Recopedia'
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
    },
    methods: {
        async login() {
            if(this.optionsSosmed.isCheckbox == false) {
                // this.notif_alert.alertType = 'alert-danger';
                // this.notif_alert.alert = 'block';
                // this.notif_alert.alertMessage = 'Mohon, untuk menceklsi bagaian ijin';
                this.loadLogin = false;
                this.isRead = false;
                return;
            }
            const payload = {
                email: this.email,
                password: this.password,
                device_name: 'web',
            };
            this.error = null;
            this.loadLogin = true;
            this.isRead = true;
            try {
                const response = await AuthService.login(payload);
                if (response.data.result == 'success') {
                    this.loadLogin = false;
                    this.isRead = false;

                    const token = response.data.token.split('|')[1];

                    this.$store.dispatch('auth/setToken', { value: token });
                    this.$store.dispatch('auth/setEmail', {
                        value: this.email,
                    });

                    await this.$store.dispatch('auth/getAuthUser');

                    this.$store.dispatch('auth/setMenuActive', {
                        value: 'dashboard',
                    });
                    this.$store.dispatch('auth/setMessage', { value: '' });
                    this.$router.push('/dashboard');
                } else {
                    this.loadLogin = false;
                    this.isRead = false;

                    this.notif_alert.alertType = 'alert-danger';
                    this.notif_alert.alert = 'block';
                    this.notif_alert.alertMessage = response.data.message;
                    const error = Error(
                        'Unable to fetch user after login, check your API settings.'
                    );
                    error.name = 'Fetch User';
                    throw error;
                }
            } catch (error) {
                this.error = getError(error);
            }
        },
        onRegisterCheck() {
            if (this.$store.getters['auth/getMessage'] != '') {
                this.notif_alert.alert = 'block';
                this.notif_alert.alertType = 'alert-success';
                this.notif_alert.alertMessage =
                    this.$store.getters['auth/getMessage'];

                this.$store.dispatch('auth/setMessage', { value: '' });
            }
        },
        onListenLogin(val) {
            this.isRead = val;
            this.loadLogin = val;
        },
        handleCheckBox() {
            if(this.optionsSosmed.isCheckbox) {
                this.optionsSosmed.isCheckbox = false;
            } else {
                this.optionsSosmed.isCheckbox = true;
            }
        }
    },
};
</script>
