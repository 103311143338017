<template>
    <div class="container-fluid">
        <div class="page-content pic-page" id="page-content">
            <div class="header-greeting mb-3">
                <p class="p-0 m-0">
                    Recopedia
                </p>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <h1 class="fw-bolder">Dokter</h1>
                    </div>
                </div>
            </div>
            <div class="patient-card mb-5">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card">
                            <div class="px-3 py-3">
                                <form @submit.prevent="onLoadDokter">
                                    <div class="row filter-input">
                                        <div class="col-12">
                                            <div class="search-input">
                                                <input type="text"  v-model="search" v-on:keyenter="onFilter()" name="patient" id="patient-search" class="form-control" placeholder="Cari Dokter">
                                                <i class="fas fa-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                                <div class="table-responsive">
                                    <!-- Should be DataTables -->
                                    <table class="table">
                                        <thead>
                                            <tr class="text-left">
                                                <td>No</td>
                                                <td>Nama Lengkap</td>
                                                <td>SIP</td>
                                                <td>Pasien</td>
                                                <td>No HP</td>
                                                <td>Terakhir Login</td>
                                                <td>Aksi</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                             <tr v-if="loadDokter">
                                                <td
                                                    colspan="7"
                                                    class="text-center"
                                                >
                                                    <h1>
                                                        <i
                                                         style="color: #4d3180"
                                                            class="
                                                                fa
                                                                fa-spinner
                                                                fa-spin
                                                            "
                                                        ></i>
                                                    </h1>
                                                </td>
                                            </tr>
                                             <template
                                                v-for="(
                                                    item, index
                                                ) in listDokter"
                                                v-else
                                            >
                                                <tr class="align-middle text-left" :key="item.id">
                                                    <td>{{ index + index_data }}</td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.npa}}</td>
                                                    <td>{{item.total_pasien_format}}</td>
                                                    <td>{{item.phone}}</td>
                                                    <td>{{item.last_login_format}}</td>
                                                    <td>
                                                      <router-link
                                                            :to="{
                                                                name: 'pic_dokter_detail',
                                                                params: {
                                                                    id: item.id,
                                                                },
                                                            }"
                                                            style="
                                                                text-decoration: none;
                                                            "
                                                        >
                                                            <button type="button" class="d-inline btn btn-details my-1" data-bs-toggle="modal" data-bs-target="#modalPatient"><i class="fas fa-eye me-2"></i>Lihat</button>
                                                      </router-link>
                                                    </td>
                                                </tr>
                                             </template>
                                        </tbody>
                                    </table>
                                </div>
                                   <div class="table-info" v-if="paging_show">
                                    <h6 class="mb-0 text-muted">
                                        <small>Rows per page: </small>
                                    </h6>
                                    <div class="dropdown-container">
                                        <select
                                            name="rows"
                                            id="pageRow"
                                            class="form-select page-select"
                                            v-on:change="onChangePerpage()"
                                            v-model="perpage"
                                        >
                                            <option value="7">7</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                        </select>
                                        <!-- <i class="fas fa-chevron-down"></i> -->
                                    </div>
                                    <div class="mx-3 paging-of">
                                        <small>{{ page_info }}</small>
                                    </div>
                                    <button
                                        class="btn"
                                        :disabled="disabled_prev"
                                        v-on:click="prevPage()"
                                    >
                                        <i class="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                        class="btn"
                                        :disabled="disabled_next"
                                        v-on:click="nextPage()"
                                    >
                                        <i class="fas fa-chevron-right"></i>
                                    </button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getError } from "@/utils/helpers";
import PicService from "@/services/PicService";

export default {
    data() {
        return {
            listDokter:[],
            page: 1,
            loadDokter:false,
            perpage: 7,
            last_page: 1,
            page_info: '',
            index_data: 1,
            paging_show:false,
            disabled_next: false,
            disabled_prev: false,
            search:"",
        };
    },
    created() {
        document.title = 'Dokter'
        document.body.setAttribute('id', 'pic');
    },
    mounted() {
        this.onLoadDokter();
    },
    methods:{
        async onLoadDokter() {
            this.loadDokter = true;
            const payload = {
                params: {
                    search: this.search,
                    perpage: this.perpage,
                    page: this.page,
                },
            };
            await PicService.getDokter(payload)
                .then((response) => this.onResultDokter(response))
                .catch((error) => getError(error));
        },
        onResultDokter(response) {
            this.listDokter = response.data.data;
            this.loadDokter = false;
            this.last_page = response.data.last_page;
            if(this.listDokter.length > 0) {
                this.paging_show = true
            } else {
                this.paging_show = false
            }

            this.page_info =
                response.data.current_page +
                '-' +
                response.data.last_page +
                ' of ' +
                response.data.to;
            this.index_data = response.data.from;

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        onFilter() {
            this.page = 1;
            this.onLoadDokter();
        },
        nextPage() {
            if (this.page < this.last_page) {
                this.page++;
                this.onLoadDokter();
            }

            if (this.page < this.last_page) {
                this.disabled_next = false;
            } else {
                this.disabled_next = true;
            }
        },
        prevPage() {
            if (this.page > 1) {
                this.page--;
                this.onLoadDokter();
            }

            if (this.page == 1) {
                this.disabled_prev = true;
            } else {
                this.disabled_prev = false;
            }
        },
        onChangePerpage() {
            this.page = 1;
            this.onLoadDokter();
        },
    }
};
</script>
