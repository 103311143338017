<template>
    <div class="container-fluid">
        <div class="page-content pic-page"  id="page-content">
            <div class="header-greeting mb-4">
                <p class="p-0 m-0">
                   Recopedia
                </p>
                <div class="d-flex justify-content-between">
                    <h1 class="fw-bolder mb-0">Profil</h1>
                    <button v-if="loadLogout" type="button" class="btn btn-add-main px-5" disabled><i class="fa fa-spinner fa-spin"></i>  Log Out</button>
                    <button v-else type="button" class="btn btn-add-main px-5" v-on:click="onLogout()">Log Out</button>
                </div>
            </div>
            <div id="profile-section" class="my-5">
                
                <div class="row">
                    <div class="col-12">
                        <div
                            class="alert"
                            v-bind:class="[notif_alert.alertType]"
                            role="alert"
                            v-bind:style="{
                                display: notif_alert.alert,
                            }"
                        >
                            {{ notif_alert.alertMessage }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <div class="card">
                             <div class="photo-row" type="button" v-on:click="onShowUpload()">
                                <div class="photo-profile">
                                    <img :src="autPic.avatar" alt="" class="img-fluid">
                                    <i class="border border-white border-4 text-white rounded-circle fas fa-pencil-alt p-1"></i>
                                </div>
                            </div>
                            <div class="name-row mb-3 mt-2">
                                <div class="name fw-bold">{{autPic.name}}</div>
                            </div>
                            <div class="data-row border-top">
                                <div class="p-3">
                                    <div class="title">Jumlah Dokter</div>
                                    <div class="title-text">{{autPic.total_dokter}}</div>
                                </div>
                            </div>
                            <!-- <div class="data-row border-top">
                                <div class="p-3">
                                    <div class="title">No HP (WhatsApp)</div>
                                    <div class="title-text">{{autPic.phone}}</div>
                                </div>
                            </div> -->
                            <div class="data-row border-top">
                                <div class="p-3">
                                    <div class="title">Terakhir Login</div>
                                    <div class="title-text">Terakhir Login
                                        {{autPic.last_login_format}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        
                        <div class="card p-3">
                            <form action="#" id="profile-form">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Nama</label>
                                            <input type="text" class="form-control" v-model="dataset.name" :readonly="isRead" id="exampleFormControlInput1">
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput2" class="form-label">Nama Belakang</label>
                                            <input type="text" class="form-control" id="exampleFormControlInput2" :readonly="isRead">
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput3" class="form-label">Nomor HP</label>
                                            <input type="text" class="form-control" :readonly="isRead" v-model="dataset.phone" id="exampleFormControlInput3" >
                                        </div>
                                    </div> -->
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput4" class="form-label">Email</label>
                                            <input type="email" class="form-control" :readonly="isRead" v-model="dataset.email" id="exampleFormControlInput4" >
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput6" class="form-label">Password Lama</label>
                                            <input type="password" v-model="dataset.old_password" :readonly="isRead" v-on:keyup="onKeyUpPassword()" class="form-control" id="exampleFormControlInput6" placeholder="Password Lama">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput6" class="form-label">Password Baru</label>
                                            <input type="password" v-model="dataset.new_password" :readonly="isRead"  :disabled="chang_password" class="form-control" id="exampleFormControlInput6" placeholder="Password Baru">
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput7" class="form-label">NPA</label>
                                            <input type="text" class="form-control" id="exampleFormControlInput7" value="321/SKL/231/2021">
                                        </div>
                                    </div> -->
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3">
                                            <label for="exampleFormControlInput8" class="form-label">Konfirmasi Password</label>
                                            <input type="password" v-model="dataset.confirm_password" :readonly="isRead" :disabled="chang_password" class="form-control" id="exampleFormControlInput8" placeholder="Konfirmasi Password">
                                        </div>
                                    </div>
                                    <div class="col-12 my-2 mt-md-4">
                                       <button v-if="load" type="button" class="btn btn-add-main px-5" disabled><i class="fa fa-spinner fa-spin"></i> Simpan Perubahan</button>
                                       <button v-else v-on:click="onSubmit()" type="button" class="btn btn-add-main px-5">Simpan Perubahan</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CHART Placeholde -->

            <!-- END CHART Placeholde -->
        </div>
        <upload-image :options="optionUpload" :notif="notif_alert"></upload-image>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getError } from '@/utils/helpers';
import PicService from '@/services/PicService';
import UploadImage from "@/components/UploadImage";

export default {
    name:"Profile",
    components:{UploadImage},
    data() {
        return {
            loadLogout:false,
            load:false,
            isRead:false,
            chang_password:true,
            dataset: {
                npa:"",
                name:"",
                email:"",
                foto:"",
                jumlah_pasien:"",
                phone:"",
                old_password:"",
                new_password:"",
                confirm_password:"",
            },
            notif_alert: {
                alert: 'none',
                alertMessage: '',
                alertType: '',
            },
            optionUpload:{
                isShowModal:'',
                displayModal:'none',
                backdrop:'',
                form:"pic"
            },
            update_password:true
        }
    },
    computed:{
        ...mapGetters('pic', ['autPic']),
        computeMarginLeft: function () {
            const width = window.innerWidth;
            if (width >= 768) {
                return '280px';
            }
            if (width < 768) {
                return '80px';
            }
            return '0px';
        },
    },
    created() {
        this.dataset.email = this.autPic.email
        this.dataset.name = this.autPic.name
        this.dataset.phone = this.autPic.phone
        document.title = 'Profil'
        document.body.setAttribute('id', 'pic');
    },
    methods: {
        async onLogout() {
            this.loadLogout = true
            await this.$store.dispatch('pic/logout');
            this.$store.dispatch('pic/setMenuActive', { value: "dashboard" });
            this.loadLogout = false
        },
        async onSubmit() {
            this.load = true;
            this.isRead = true;

            if(this.dataset.old_password && this.dataset.new_password) {
                await this.onUpdatePassword()
            }
            if(this.update_password == true) {
                const payload = {
                    email: this.dataset.email,
                    npa: this.dataset.npa,
                    phone: this.dataset.phone,
                    name:this.dataset.name,
                };
                await PicService.updateUser(payload)
                    .then((response) => this.onResponse(response))
                    .catch((error) => this.onError(error));
            }
        },
        async onUpdatePassword() {
            const payload = {
                old_password: this.dataset.old_password,
                password: this.dataset.new_password,
                password_confirmation: this.dataset.confirm_password,
            };
            await PicService.updateUserPassword(payload)
                .then((response) => this.onResponsePassword(response))
                .catch((error) => this.onError(error));
        },
        onResponsePassword(response){
            if(response.data.result == "error") {
                this.notif_alert.alertType ="alert-danger"
                this.notif_alert.alert="block"
                this.notif_alert.alertMessage= response.data.message
                this.update_password = false
                this.load = false
                this.isRead = false
            } else {
                this.notif_alert.alertType = "alert-success"
                this.notif_alert.alert="block"
                this.notif_alert.alertMessage = response.data.message
                this.update_password = true
            }
        },
        onError(error) {
              this.notif_alert.alertType ="alert-danger"
              this.notif_alert.alert="block"
              this.notif_alert.alertMessage = getError(error)
              this.load = false
              this.isRead = false
        },
        async onResponse(response) {
            if(response.data.result == "error") {
                this.notif_alert.alertType ="alert-danger"
                this.notif_alert.alert="block"
             
                this.notif_alert.alertMessage= response.data.message
            } else {
                await this.$store.dispatch('pic/getAuthUser');
                this.notif_alert.alertType = "alert-success"
                this.notif_alert.alert="block"
                if(this.notif_alert.alertMessage) {
                    this.notif_alert.alertMessage= response.data.message+" & "+this.notif_alert.alertMessage
                } else {
                    this.notif_alert.alertMessage= response.data.message
                }
                this.dataset.old_password = ""
                this.dataset.new_password = ""
                this.dataset.confirm_password = ""
            }
            this.load = false
            this.isRead = false
        },
        onShowUpload() {
            if (this.optionUpload.isShowModal == 'show') {
                this.optionUpload.isShowModal = '';
                this.optionUpload.displayModal = 'none';
                this.optionUpload.backdrop = '';
            } else {
                this.optionUpload.isShowModal = 'show';
                this.optionUpload.displayModal = 'block';
                this.optionUpload.backdrop = 'modal-backdrop show';
            }
        },
        onKeyUpPassword(){
            if(this.dataset.old_password) {
                this.chang_password = false
            } else {
                  this.chang_password = true
            }
        }
    }
}
</script>
